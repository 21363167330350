import {useState, useEffect} from 'react';
import debounce from 'lodash/debounce';

import {ScrollDirection} from '@/enums';

export const useScrollDirection = (
  isScrollDirectionTracked: boolean = true,
) => {
  const [scrollDirection, setScrollDirection] = useState(ScrollDirection.Up);

  useEffect(() => {
    if (window && isScrollDirectionTracked) {
      let previousScrollY =
        window.scrollY || document.documentElement.scrollTop;

      const updateScrollDirection = () => {
        const currentScrollY =
          window.scrollY || document.documentElement.scrollTop;

        const newScrollDirection =
          currentScrollY > previousScrollY
            ? ScrollDirection.Down
            : ScrollDirection.Up;

        setScrollDirection(newScrollDirection);
        previousScrollY = currentScrollY;
      };

      const handleScroll = () => {
        debounce(
          () => window.requestAnimationFrame(updateScrollDirection),
          100,
        );
      };
      window.addEventListener('scroll', handleScroll, {passive: true});
      window.addEventListener('touchmove', handleScroll, {
        passive: true,
      });

      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleScroll);
      };
    }
  }, [isScrollDirectionTracked]);

  return scrollDirection;
};
