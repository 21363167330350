import {cva} from 'class-variance-authority';
import {useRef, useState, type ReactNode} from 'react';

import {FOCUSABLE_TAGS, useFocusTrap} from '@/hooks/useFocusTrap';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useOnClickOutside} from '@/hooks/useOnClickOutside';
import {useTranslations} from '@/hooks/useTranslations';
import Icon from '@/components/base/elements/Icon/Icon';
import {IconEnum} from '@/enums';

import MobileLinkList from './MobileLinkList';
import type {Link} from './types';

export const styles = cva(
  'lg:hidden absolute top-0 inset-x-4 sm:inset-x-[30px] md:inset-x-[50px] mt-[9.5rem] max-h-[calc(100dvh-14rem)] p-6 rounded-lg overflow-y-scroll transition-all duration-500',
  {
    variants: {
      isHidden: {
        true: 'invisible opacity-0 -translate-y-6',
        false: 'opacity-100 translate-y-0',
      },
      mode: {
        light: 'bg-white text-black shadow-light',
        dark: 'bg-black text-white shadow-dark',
      },
    },
  },
);

const labelStyles = cva('font-bold text-body-sm leading-4 uppercase', {
  variants: {
    mode: {
      light: 'text-shade-60',
      dark: 'text-shade-40',
    },
  },
});

const listItemStyles = cva(
  'flex flex-col gap-y-6 pb-6 border-b last:pb-0 last:border-b-0',
  {
    variants: {
      mode: {
        light: 'border-shade-30',
        dark: 'border-shade-60',
      },
    },
  },
);

const overviewStyles = cva('pb-6 border-b text-body-lg font-bold leading-4', {
  variants: {
    mode: {
      light: 'border-shade-30',
      dark: 'border-shade-60',
    },
  },
});

export interface MobileProps {
  mode: 'light' | 'dark';
  pageTitle: string;
  links: Link[];
  sectionTitle: string;
  sectionOverviewUrl: string;
  navigationSearch?: ReactNode;
  searchText?: string;
  searchPlaceholder?: string;
  searchPlaceholderMobile?: string;
}

export function MobileNavigation({
  mode,
  links,
  pageTitle,
  sectionTitle,
  sectionOverviewUrl,
  navigationSearch = null,
}: MobileProps) {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  const tier1Query = FOCUSABLE_TAGS.split(', ')
    .map((tag) => `:scope ${tag}[data-secondary-nav-tier="1"]`)
    .join(', ');
  const tier2Query = FOCUSABLE_TAGS.split(', ')
    .map(
      (tag) =>
        `:scope [data-active="true"] ${tag}[data-secondary-nav-tier="2"], :scope [data-active="true"] ${tag}[data-secondary-nav-tier="3"]`,
    )
    .join(', ');
  useFocusTrap(ref, !isHidden && !activeIndex, tier1Query);
  useFocusTrap(ref, activeIndex !== null, tier2Query);
  useOnClickOutside(() => setIsHidden(true));
  useKeyPress('Escape', () => {
    if (activeIndex === null) {
      setIsHidden(true);
    } else {
      setActiveIndex(null);
    }
  });
  const {t} = useTranslations();

  return (
    <nav
      className="lg:hidden basis-1/2 grow sm:basis-auto max-w-full"
      data-click-outside="dismiss"
    >
      <div className="text-body-base overflow-hidden">
        <button
          type="button"
          aria-expanded={!isHidden}
          aria-haspopup="true"
          aria-controls="id_mobile_secondary_nav"
          className="w-full text-left leading-[44px] outline-4 -outline-offset-4 outline-state-focus focus-visible:outline"
          onClick={() => setIsHidden(!isHidden)}
        >
          <span className="flex items-center">
            <span className="flex flex-col gap-y-1 w-full truncate">
              <span className="text-body-sm opacity-60 font-bold uppercase leading-4">
                {sectionTitle}
              </span>
              <span className="text-lg font-bold leading-5">{pageTitle}</span>
            </span>
            <Icon
              className="shrink-0 w-5 h-7 ml-auto"
              icon={IconEnum.Chevron}
              options={{
                orientation: isHidden ? 'down' : 'up',
              }}
            />
          </span>
        </button>
      </div>
      <div
        ref={ref}
        id="id_mobile_secondary_nav"
        className={styles({isHidden, mode})}
      >
        {navigationSearch}
        <ul className="flex flex-col gap-y-6">
          {sectionOverviewUrl && (
            <li className={overviewStyles({mode})}>
              <a href={sectionOverviewUrl}>{t('nav:overview')}</a>
            </li>
          )}
          {links.map((tier1Link: Link, tier1Index: number) => (
            <li
              key={`${tier1Link.text}${tier1Index}`}
              className={listItemStyles({mode})}
            >
              {tier1Link.links && tier1Link.links.length > 0 ? (
                <span className={labelStyles({mode})}>{tier1Link.text}</span>
              ) : (
                <span className="text-body-lg font-bold leading-4">
                  <a href={tier1Link.url}>{tier1Link.text}</a>
                </span>
              )}
              {tier1Link.links && tier1Link.links.length > 0 && (
                <ul className="gap-6 grid grid-cols-1 sm:grid-cols-2">
                  {tier1Link.links.map(
                    (tier2Link: Link, tier2Index: number) => (
                      <li
                        key={`${tier2Link.label}${tier2Index}`}
                        className="flex flex-col gap-y-6"
                      >
                        <span className="flex flex-col gap-y-6 text-lg font-bold leading-[20px]">
                          {tier2Link.links?.length ? (
                            <>
                              <button
                                type="button"
                                aria-expanded={activeIndex === tier2Index}
                                aria-controls={`id_${tier2Link.text}_${tier2Index}_mobile`}
                                className="w-full text-left leading-[44px] my-[-10px] outline-4 outline-state-focus focus-visible:outline"
                                onClick={() =>
                                  setActiveIndex(
                                    activeIndex === tier2Index
                                      ? null
                                      : tier2Index,
                                  )
                                }
                                data-secondary-nav-tier="2"
                              >
                                <span className="flex items-center w-full">
                                  {tier2Link.text}
                                  <Icon
                                    className="shrink-0 w-5 h-7 ml-auto"
                                    icon={IconEnum.Chevron}
                                    options={{
                                      orientation:
                                        activeIndex !== tier2Index
                                          ? 'down'
                                          : 'up',
                                    }}
                                  />
                                </span>
                              </button>
                              <MobileLinkList
                                key={`${tier2Link.label}${tier2Index}`}
                                id={`id_${tier2Link.text}_${tier2Index}_mobile`}
                                isActive={activeIndex === tier2Index}
                              >
                                {tier2Link.links.map(
                                  (tier3Link: Link, tier3Index: number) => (
                                    <li key={`${tier3Link.text}${tier3Index}`}>
                                      <a
                                        href={tier3Link.url}
                                        className="text-body-base font-medium hover:underline outline-4 outline-state-focus focus-visible:outline"
                                        data-secondary-nav-tier="3"
                                      >
                                        {tier3Link.text}
                                      </a>
                                    </li>
                                  ),
                                )}
                              </MobileLinkList>
                            </>
                          ) : (
                            <a
                              href={tier2Link.url}
                              className="text-body-lg hover:underline outline-4 outline-state-focus focus-visible:outline"
                              data-secondary-nav-tier="2"
                            >
                              {tier2Link.text}
                            </a>
                          )}
                        </span>
                      </li>
                    ),
                  )}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}
